import styled, { device, themeGet } from "@styled";
import BackgroundImage from "gatsby-background-image";

export const TestimonialWrapper = styled(BackgroundImage)`
    padding-block-start: 60px;
    padding-block-end: 60px;
    ${device.medium} {
        padding-block-start: 80px;
        padding-block-end: 80px;
    }
    ${device.large} {
        padding-block-start: 100px;
        padding-block-end: 100px;
    }
`;
