import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useFormUrl } from "@hooks";
import { hasKey } from "@utils";
import { Row, Col } from "@ui/wrapper";
import { FormGroup, Input, Select, Textarea } from "@ui/form-elements";
import Button from "@ui/button";

const ConsultForm = () => {
    const formUrl = useFormUrl();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [serverState, setServerState] = useState({
        submitting: false,
        status: null,
    });
    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg },
        });
        if (ok) {
            form.reset();
        }
    };
    const onSubmit = (data, e) => {
        console.log(data)
        const form = e.target;
        setServerState({ submitting: true });
        axios({
            method: "post",
            url: "https://api.innovo-erp.com/api/Values",
            data: data,
        })
            .then((r) => {
                handleServerResponse(true, "¡Gracias! para contactar con nosotros", form);
            })
            .catch((r) => {
                console.log(r);
                handleServerResponse(false, r.response.data.error, form);
            });
         
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row gutters={{ lg: 20 }}>
                <Col lg={12}>
                    <FormGroup mb="20px">
                        <Input
                            id="consult_name"
                            type="text"
                            placeholder="Nombres y apellidos *"
                            hover={2}
                            feedbackText={errors?.consult_name?.message}
                            state={
                                hasKey(errors, "consult_name")
                                    ? "error"
                                    : "success"
                            }
                            showState={!!hasKey(errors, "consult_name")}
                            {...register("consult_name", {
                                required: "Nombres y apellidos es requerido",
                            })}
                        />
                    </FormGroup>
                </Col>
                <Col lg={12}>
                    <FormGroup mb="20px">
                        <Input
                            id="consult_email"
                            type="email"
                            placeholder="Email *"
                            hover={2}
                            feedbackText={errors?.consult_email?.message}
                            state={
                                hasKey(errors, "consult_email")
                                    ? "error"
                                    : "success"
                            }
                            showState={!!hasKey(errors, "consult_email")}
                            {...register("consult_email", {
                                required: "Email is required",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "invalid email address",
                                },
                            })}
                        />
                    </FormGroup>
                </Col>
                <Col lg={12}>
                    <FormGroup mb="20px">
                        <Input
                            id="consult_phone"
                            type="text"
                            placeholder="Celular *"
                            hover={2}
                            feedbackText={errors?.consult_phone?.message}
                            state={
                                hasKey(errors, "consult_phone")
                                    ? "error"
                                    : "success"
                            }
                            showState={!!hasKey(errors, "consult_phone")}
                            {...register("consult_phone", {
                                required: "El número de celular es requerido",
                                pattern: {
                                    value: /\)?([0-9]{3})\2([0-9]{6})/,
                                    message: "Número de celular inválido",
                                },
                            })}
                        />
                    </FormGroup>
                </Col>
                <Col lg={12}>
                    <FormGroup mb="20px">
                        <Input
                            id="consult_business"
                            type="text"
                            placeholder="Comentario *"
                            hover={2}
                            feedbackText={errors?.consult_business?.message}
                            state={
                                hasKey(errors, "consult_business")
                                    ? "error"
                                    : "success"
                            }
                            showState={!!hasKey(errors, "consult_business")}
                            {...register("consult_business", {
                                required: "Nombre de empresa es requerido",
                            })}
                        />
                    </FormGroup>
                </Col>
                {/*<Col lg={12}>*/}
                {/*    <FormGroup mb="20px">*/}
                {/*        <Select*/}
                {/*            id="consult_inquiry"*/}
                {/*            hover={2}*/}
                {/*            feedbackText={errors?.consult_inquiry?.message}*/}
                {/*            state={*/}
                {/*                hasKey(errors, "consult_inquiry")*/}
                {/*                    ? "error"*/}
                {/*                    : "success"*/}
                {/*            }*/}
                {/*            showState={!!hasKey(errors, "consult_inquiry")}*/}
                {/*            {...register("consult_inquiry", {*/}
                {/*                required: "Please select a inquiry",*/}
                {/*            })}*/}
                {/*        >*/}
                {/*            <option value="">Your inquiry about</option>*/}
                {/*            <option value="General Information Request">*/}
                {/*                General Information Request*/}
                {/*            </option>*/}
                {/*            <option value="Partner Relations">*/}
                {/*                Partner Relations*/}
                {/*            </option>*/}
                {/*            <option value="Careers">Careers</option>*/}
                {/*            <option value="Software Licencing">*/}
                {/*                Software Licencing*/}
                {/*            </option>*/}
                {/*        </Select>*/}
                {/*    </FormGroup>*/}
                {/*</Col>*/}
                {/*<Col lg={12}>*/}
                {/*    <FormGroup mb="30px">*/}
                {/*        <Textarea*/}
                {/*            id="consult_message"*/}
                {/*            placeholder="Por favor, describa su consulta."*/}
                {/*            hover={2}*/}
                {/*            state={*/}
                {/*                hasKey(errors, "consult_message")*/}
                {/*                    ? "error"*/}
                {/*                    : "success"*/}
                {/*            }*/}
                {/*            feedbackText={errors?.consult_message?.message}*/}
                {/*            showState={!!hasKey(errors, "consult_message")}*/}
                {/*            {...register("consult_message", {*/}
                {/*                required: "El texto es necesario",*/}
                {/*                maxLength: {*/}
                {/*                    value: 50,*/}
                {/*                    message: "Maximum length is 50",*/}
                {/*                },*/}
                {/*                minLength: {*/}
                {/*                    value: 10,*/}
                {/*                    message: "Minimum length is 10",*/}
                {/*                },*/}
                {/*            })}*/}
                {/*        />*/}
                {/*    </FormGroup>*/}
                {/*</Col>*/}
                <Col lg={12}>
                    <Button
                        type="submit"
                        disabled={serverState.submitting}
                        hoverStyle="two"
                        px="54px"
                    >
                        Contacto
                    </Button>
                    {serverState.status && (
                        <p
                            className={`form-output ${
                                !serverState.status.ok ? "errorMsg" : "success"
                            }`}
                        >
                            {serverState.status.msg}
                        </p>
                    )}
                </Col>
            </Row>
        </form>
    );
};

export default ConsultForm;
