import React from "react";
import {
    ButtonWrap,
    MissionContentWrap,
    OurMissionImageWrap,
    OurMissionWrap,
    VideoBtnWrap,
} from "../../about/layout-02/style";
import PropTypes from "prop-types";
import { Col, Row } from "../../../components/ui/wrapper";
import Image from "../../../components/ui/image";
import VideoButton from "../../../components/ui/video-button";
import Text from "../../../components/ui/text";
import List, { ListItem } from "../../../components/ui/list";
import Button from "../../../components/ui/button";
import {
    ButtonType,
    ImageType,
    ItemType,
    TextType,
    VideoButtonType,
} from "../../../utils/types";
import Accordion from "../../../components/ui/accordion";
import { graphql, useStaticQuery } from "gatsby";
import { convertToBgImage } from "gbimage-bridge";
import { getImage } from "gatsby-plugin-image";

const Products = ({ data }) => {
    const productButton = useStaticQuery(graphql`
        query ProductButtonQuery {
            file(relativePath: { eq: "innovo/products/products-button.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: FULL_WIDTH
                        placeholder: TRACED_SVG
                        formats: WEBP
                        quality: 100
                    )
                }
            }
        }
    `);

    const buttonImage = convertToBgImage(getImage(productButton.file)).fluid;

    return (
      <OurMissionWrap>
          <Row>
              <Col lg={6}>
                  <OurMissionImageWrap>
                      {data?.images?.[0]?.src && (
                        <Image
                          src={data.images[0].src}
                          alt={data.images[0]?.alt || "Our Mission"}
                        />
                      )}
                      {data?.video_button && (
                        <VideoBtnWrap>
                            <VideoButton
                              color="primary"
                              link={data.video_button?.link}
                            />
                        </VideoBtnWrap>
                      )}
                  </OurMissionImageWrap>
              </Col>
              <Col lg={{ span: 5, offset: 1 }}>
                  <MissionContentWrap>
                      {data.texts?.[0].content && (
                        <Text
                          fontSize="18px"
                          mb="30px"
                          dangerouslySetInnerHTML={{
                              __html: data.texts[0].content
                          }}
                          style={{ color: "#ffffff" }}
                        />
                      )}
                      {data?.items && (
                        <Accordion allowZeroExpanded data={data.items} />
                      )}
                      {data?.buttons && (
                        <ButtonWrap>
                            {data.buttons.map(({ id, content, ...rest }) => (
                              <Button
                                style={{maxWidth: "250px"}}
                                imgbutton={true}
                                key={id} {...rest}>
                                  <Image
                                    src={buttonImage.src}
                                    alt={"Cotiza Ahora"}
                                  />
                              </Button>
                            ))}
                        </ButtonWrap>
                      )}
                  </MissionContentWrap>
              </Col>
          </Row>
      </OurMissionWrap>
    );
}

Products.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
        video_button: PropTypes.shape(VideoButtonType),
        texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
        list: PropTypes.arrayOf(PropTypes.string),
        buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    }),
};

export default Products;
