import React from "react";
import { FloatingButton } from "./style";

const WhatsAppButton = () => {
    return (
        <FloatingButton
            href="https://wa.me/+51985669265"
            target="_blank"
            isStick={true}
            rel="noopener noreferrer"
        >
            <i className="fab whatsapp-icon fa-whatsapp"></i>
        </FloatingButton>
    );
};

export default WhatsAppButton;
