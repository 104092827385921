import BackgroundImage from "gatsby-background-image";
import styled, { device } from "@styled";

export const AboutTabAreaWrapper = styled(BackgroundImage)`
    padding-block-start: 53px;
    padding-block-end: 60px;
    ${device.medium} {
        padding-block-start: 73px;
        padding-block-end: 80px;
    }
    ${device.large} {
        padding-block-start: 91px;
        padding-block-end: 100px;
    }
`;

export const StyledTab = styled.section`
    background-color: transparent;
`;
