import React, { Children } from "react";
import PropTypes from "prop-types";
import { Container } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Tabs, { Tab } from "@ui/tabs";
import { SectionTitleType } from "@utils/types";
import { graphql, useStaticQuery } from "gatsby";
import { convertToBgImage } from "gbimage-bridge";
import { getImage } from "gatsby-plugin-image";
import { ProductTabAreaWrapper, StyledTab } from "./style";

const ProductTabArea = ({ id, data, children }) => {
    const childArr = Children.toArray(children);

    const productBg = useStaticQuery(graphql`
        query ProductQuery {
            file(relativePath: { eq: "bg/products-bg.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: FULL_WIDTH
                        placeholder: TRACED_SVG
                        formats: WEBP
                        quality: 100
                    )
                }
            }
        }
    `);
    const bgImage = convertToBgImage(getImage(productBg.file));

    return (
        <ProductTabAreaWrapper id={id} {...bgImage}>
            <StyledTab>
                <Container>
                    {data?.section_title && (
                        <SectionTitle
                            mb={["50px", null, null, "60px"]}
                            title={data.section_title?.title}
                            subtitle={data.section_title?.subtitle}
                            titleStyle={{ color: "#ffffff" }}
                            subtitleStyle={{ color: "#ffffff" }}
                        />
                    )}
                    <Tabs defaultActiveKey={childArr[0].props.data.id}>
                        {childArr.map((item) => (
                            <Tab
                                key={item.props.data.id}
                                eventKey={item.props.data.id}
                                title={item.props.data.title}
                            >
                                {item}
                            </Tab>
                        ))}
                    </Tabs>
                </Container>
            </StyledTab>
        </ProductTabAreaWrapper>
    );
};

ProductTabArea.propTypes = {
    id: PropTypes.string,
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
    }),
    children: PropTypes.node.isRequired,
};

export default ProductTabArea;
