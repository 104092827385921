import styled, { css, themeGet } from "@styled";

export const FloatingButton = styled.a`
    position: fixed;
    right: 30px;
    bottom: 60px;
    z-index: 999;
    box-shadow: 0 30px 50px rgba(0, 0, 0, 0.03);
    display: block;
    padding: 0;
    border: none;
    width: 60px;
    height: 60px;
    border-radius: ${themeGet("radii.round")};
    text-align: center;
    font-size: 25px;
    line-height: 60px;
    cursor: pointer;
    opacity: 1;
    visibility: visible;
    background-color: #25d366;
    color: #ffffff;
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    overflow: hidden;

    .whatsapp-icon {
        margin-top: 16px;
    }

    @media screen and (max-width: 767px) {
        .whatsapp-icon {
            margin-top: 10px;
        }

        .whatsapp_float {
            width: 40px;
            height: 40px;
            bottom: 20px;
            right: 10px;
            font-size: 22px;
        }
    }
`;
