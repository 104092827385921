import React from "react";
import PropTypes from "prop-types";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import { Container, Row, Col } from "@ui/wrapper";
import Heading from "@ui/heading";
import Text from "@ui/text";
import VideoButton from "@ui/video-button";
import AppointmentForm from "@components/forms/appointment-form";
import { HeadingType, VideoButtonType, ImageType } from "@utils/types";
import {
    HeroWrapper,
    HeroTextWrap,
    HeroButton,
    AppointmentWrap,
    AppointmentTitleWrap,
    AppointmentFormWrap,
    StyledSubtitle,
    StyledTitle,
    HeroSlider,
    HeroImage,
    HeroContainer,
} from "./style";
import SwiperSlider, { SwiperSlide } from "../../../components/ui/swiper";
import ConsultForm from "../../../components/forms/consult-form";

const sliderSettings = {
    slidesPerView: 1,
    loop: true,
    spaceBetween: 30,
    autoplay: false,
    pagination: true,
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        768: {
            slidesPerView: 1,
        },
        992: {
            slidesPerView: 1,
        },
        1200: {
            slidesPerView: 1,
        },
    },
};

function CaseStudyBox(props) {
    return null;
}

CaseStudyBox.propTypes = {
    btnText: PropTypes.string,
    layout: PropTypes.number,
    image: PropTypes.any,
    path: PropTypes.string,
};
const HeroArea = ({ id, data }) => {
    return (
        <HeroWrapper id={id}>
            {data?.images.length > 0 && (
                <HeroSlider options={sliderSettings} shadowSize="lg">
                    {data?.images?.map((image, id) => (
                        <SwiperSlide key={id}>
                            <HeroImage src={image.src} alt="" />
                        </SwiperSlide>
                    ))}
                </HeroSlider>
            )}
            <HeroContainer>
                <Row alignItems="center">
                    <Col md={6}>
                        <HeroTextWrap>
                            {/*{data?.headings?.[0] && (*/}
                            {/*    <StyledSubtitle as={data.headings[0].level}>*/}
                            {/*        {data.headings[0].content}*/}
                            {/*    </StyledSubtitle>*/}
                            {/*)}*/}
                            {/*{data?.headings?.[1] && (*/}
                            {/*    <StyledTitle as={data.headings[1].level}>*/}
                            {/*        {data.headings[1].content}*/}
                            {/*    </StyledTitle>*/}
                            {/*)}*/}
                            {/*{data?.video_button && (*/}
                            {/*    <HeroButton>*/}
                            {/*        <VideoButton*/}
                            {/*            link={data.video_button.link}*/}
                            {/*            content={data.video_button.content}*/}
                            {/*        />*/}
                            {/*    </HeroButton>*/}
                            {/*)}*/}
                        </HeroTextWrap>
                    </Col>
                    <Col lg={5} md={6} className="mx-auto">
                        {data?.show_form && (
                            <AppointmentWrap>
                                <AppointmentTitleWrap>
                                    <Heading as="h5" mb="10px">
                                        Pedido de soluciones
                                    </Heading>
                                    <Text>
                                        Es un placer tener la oportunidad de
                                        apoyarte
                                    </Text>
                                </AppointmentTitleWrap>
                                <AppointmentFormWrap>
                                    <ConsultForm />
                                </AppointmentFormWrap>
                            </AppointmentWrap>
                        )}
                    </Col>
                </Row>
                <Row
                    style={{
                        marginTop: "85px",
                        width: "100%",
                        marginLeft: "32px",
                    }}
                >
                    <Col
                        sm={12}
                        md={6}
                        style={{
                            display: "flex",
                            alignContent: "start",
                        }}
                    >
                        {data?.video_button && (
                            <HeroButton>
                                <VideoButton
                                    link={data.video_button.link}
                                    content={data.video_button.content}
                                />
                            </HeroButton>
                        )}
                    </Col>
                    <Col sm={12} md={6}>
                        {data?.headings?.[0] && (
                            <StyledTitle as={data.headings[0].level}>
                                {data.headings[0].content}
                            </StyledTitle>
                        )}
                    </Col>
                </Row>
            </HeroContainer>
        </HeroWrapper>
    );
};

HeroArea.propTypes = {
    id: PropTypes.string,
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
        video_button: PropTypes.shape(VideoButtonType),
        images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
        show_form: PropTypes.bool,
    }),
};

export default HeroArea;
