const transitionDelay = 500;

exports.shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => {
    if (location.action === "PUSH") {
        window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
    } else {
        if (!location.hash.toString().includes("#")) {
            const savedPosition = getSavedScrollPosition(location);
            window.setTimeout(
                () => window.scrollTo(...(savedPosition || [0, 0])),
                transitionDelay
            );
        } else {
            document
                .getElementById(location.hash.split("#")[1])
                .scrollIntoView();
        }
    }
    return false;
};
